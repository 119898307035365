import React from 'react';
import { useDispatch } from 'react-redux';
import useIdleTimer from '../hooks/useIdleTimer';

const SessionTimer = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  useIdleTimer({ dispatch, isAuthenticated });
  return null;
};

export default SessionTimer; 