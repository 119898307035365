import axios from "axios";
import {
	//AUTH_ERRORS,
	// REFRESH_TOKEN,
	LOGOUT,
	CLEAR_PROFILE,
} from "./types";
import { env as environment } from "./environment";
import setAuthToken from "../utils/tools";
import { useFusionAuth } from '@fusionauth/react-sdk';
import {
	apiref,
	buttonAdd,
	originalgetbuttons,
	updateButton,
	deleteButton,
	getbuttonslist,
	getapibutton,
	getButtonData,
	getbuttons,
	getmasterfieldsdata,
	getCountryData,
	getCountryLinkData,
	updateTableData,
	deleteTableData,
	createSource,
	generateSecretToken,
	callbackurlHandler
} from "./button";
import { getInviteLink, getTestPreview, sendRequest } from "./reqdoc";
import { getLogs, getLogsdata } from "./logs";
import { getUsers, addUser, getUser, updateUser, deleteUser, transferOwnership } from "./users";
import { updateOrg, getbillingOrg, getOrgList, getOrg } from "./org";
import { geturltransactions, gettransactions, redirectuser } from "./billing";
import {
	getrequested,
	getrequetdata,
	getPending,
	getPendingdata,
	getReviewPending,
	getApproved,
	getApprovedata,
	getRejected,
	getRejectedata,
	sessionReport,
	searchTable,
	submitFeedbackData,
	deleteSessionId,
	getpdftojson,
	getExtractTransactionData,
	sendForReview,
} from "./table";
import { updateCallback, updateOrg as updateOrgErr } from './auth';
import { getDownloadDocument, getLastClickedDocument, approveDocument, rejectDocument } from "./viewdoc";
import ls from "localstorage-slim";

ls.config.encrypt = true;

export const refreshAuth = (data, btndata, subhead, reload = true, secondArg, thirdArg) => async (dispatch) => {
	console.log("refreshAuth called")
	console.log("******")
	console.log(ls.get("refreshToken"))
	console.log("******")

	var json = { refreshToken: ls.get("refreshToken") };
	// const { logout } = useFusionAuth();
	// try {
	console.log("api calling for refresh auth")
	await axios.post(environment.accesstoken, json).then((res) => {
		console.log("res::", res);
		//=== Logout if the refresh token has expired ===
		if (res.data.message !== "Success!") {
			console.log("LOGOUT");
			dispatch({ type: CLEAR_PROFILE });
			dispatch({ type: LOGOUT });
			// logout();
			window.location.reload();
		} else {
			console.log("ELSEEE");
			// dispatch({
			// 	type: REFRESH_TOKEN,
			// 	payload: res,
			// });
			if (res.headers.authorization) {
				ls.set("token", res.headers.authorization);
				setAuthToken(res.headers.authorization);
			}
			if (data === "apiref") {
				dispatch(apiref());
			} else if (data === "searchTable") {
				dispatch(searchTable(btndata, subhead));
			} else if (data === "sessionReport") {
				dispatch(sessionReport(btndata));
			} else if (data === "getrequested") {
				dispatch(getrequested());
			} else if (data === "getrequetdata") {
				dispatch(getrequetdata(btndata));
			} else if (data === "getPending") {
				dispatch(getPending());
			} else if (data === "getPendingdata") {
				dispatch(getPendingdata(btndata));
			} else if (data === "getReviewPending") {
				dispatch(getReviewPending(btndata));
			// } else if (data === "getReviewPending") {
			// 	dispatch(getReviewPending());
			} else if (data === "getApproved") {
				dispatch(getApproved());
			} else if (data === "getApprovedata") {
				dispatch(getApprovedata(btndata));
			} else if (data === "getRejected") {
				dispatch(getRejected());
			} else if (data === "getRejectedata") {
				dispatch(getRejectedata(btndata));
			} else if (data === "getLogs") {
				dispatch(getLogs());
			} else if (data === "getLogsdata") {
				dispatch(getLogsdata(btndata));
			} else if (data === "getUsers") {
				dispatch(getUsers());
			} else if (data === "buttonAdd") {
				dispatch(buttonAdd(btndata));
			} else if (data === "originalgetbuttons") {
				dispatch(originalgetbuttons());
			} else if (data === "updateButton") {
				dispatch(updateButton(btndata));
			} else if (data === "getInviteLink") {
				dispatch(getInviteLink(btndata));
			} else if (data === "getTestPreview") {
				dispatch(getTestPreview(btndata));
			} else if (data === "sendRequest") {
				dispatch(sendRequest(btndata));
			} else if (data === "deleteButton") {
				dispatch(deleteButton(btndata));
			} else if (data === "addUser") {
				dispatch(addUser(btndata));
			} else if (data === 'callbackurl') {
				dispatch(callbackurlHandler(btndata));
			}else if (data === "getUser") {
				dispatch(getUser(btndata));
			} else if (data === "updateUser") {
				dispatch(updateUser(btndata));
			} else if (data === "deleteUser") {
				dispatch(deleteUser(btndata));
			} else if (data === "getbillingOrg") {
				dispatch(getbillingOrg());
			} else if (data === "updateOrg") {
				dispatch(updateOrg(btndata));
			} else if (data === "geturltransactions") {
				dispatch(geturltransactions(btndata));
			} else if (data === "gettransactions") {
				dispatch(gettransactions(btndata));
			} else if (data === "redirectuser") {
				dispatch(redirectuser(btndata));
			} else if (data === "getDownloadDocument") {
				dispatch(getDownloadDocument(btndata));
			} else if (data === "getLastClickedDocument") {
				dispatch(getLastClickedDocument(btndata));
			} else if (data === "approveDocument") {
				dispatch(approveDocument(btndata));
			} else if (data === "rejectDocument") {
				dispatch(rejectDocument(btndata));
			} else if (data === "updateCallback") {
				dispatch(updateCallback(btndata));
			} else if (data === "updateOrgErr") {
				dispatch(updateOrgErr(btndata));
			} else if (data === "getOrgList") {
				dispatch(getOrgList());
			} else if (data === "getOrg") {
				dispatch(getOrg());
			} else if (data === "getbuttonslist") {
				dispatch(getbuttonslist());
			} else if (data === "getapibutton") {
				dispatch(getapibutton(btndata));
			} else if (data === "getButtonData") {
				dispatch(getButtonData(btndata));
			} else if (data === "getbuttons") {
				dispatch(getbuttons());
			} else if (data === "submitFeedbackData") {
				dispatch(submitFeedbackData(btndata));
			} else if (data === "deleteSessionId") {
				dispatch(deleteSessionId(btndata, subhead, thirdArg));
			} else if (data === "getmasterfieldsdata") {
				dispatch(getmasterfieldsdata());
			} else if (data === "getpdftojson") {
				dispatch(getpdftojson(btndata));
			} else if(data === "getExtractTransactionData") {
				dispatch(getExtractTransactionData(btndata));
            } else if (data === "sendForReview") {
				dispatch(sendForReview(btndata));
			} else if (data === "getCountryData") {
				dispatch(getCountryData());
			} else if (data === "getCountryLinkData") {
				dispatch(getCountryLinkData(btndata));
			} else if (data === "transferOwnership") {
				dispatch(transferOwnership(btndata, secondArg));

			}
			else if (data === "updateTableData") {
				dispatch(updateTableData(btndata));

			}
			else if (data === "deleteTableData") {
				dispatch(deleteTableData(btndata));

			}
			else if (data === "createTableData") {
				dispatch(createSource(btndata));

			}
			else if (data === "generateSecretToken"){
                console.log("generateSecretToken called" ,btndata,subhead,secondArg)
                dispatch(generateSecretToken(btndata,subhead,secondArg));
             }

			if (reload) {
				window.location.reload();
			}
		}
	});
	// console.log("innnnnnnrefreshAuth " + JSON.stringify(res));
	// console.log("innnnnnnrefreshAuth " + JSON.stringify(res.data.message));
	// console.log("innnnnnnrefreshAuth " + res.data.message);

	// if (JSON.stringify(res.data.message) !== "Success!") {
	// 	console.log("LOGGGGGGGGG");
	// }
	// if (JSON.stringify(res.data.message) !== '"Success!"') {
	// 	console.log("1111111111111");
	// }
	// if (res.data.message !== '"Success!"') {
	// 	console.log("2222222222222");
	// }
	// if (JSON.stringify(res.data.message) === "Success!") {
	// 	console.log("SSSSSSSSS");
	// }

	//console.log("refreshAuth header " + JSON.stringify(res.headers));

	//return;
	// } catch (err) {
	// 	dispatch({
	// 		type: AUTH_ERRORS,
	// 		payload: err.response,
	// 	});
	// }
};

export default refreshAuth;