/* eslint-disable */
import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Nav,
  Navbar,
  NavbarBrand,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  Table,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  InputGroup,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { Link, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// import { sendLogs } from "../../../actions/logs";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";

import SearchBar from "material-ui-search-bar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { logout } from "../../../actions/auth";
import RejectTable from "../../../views/documents-recieved/RejectTable";
import {
  searchTable,
  getrequested,
  getReviewPending,
  getApproved,
  getRejected,
  getpdftojson,
  getcallbacklogs,
  headerSearchInput,
} from "../../../actions/table";
import {
  changeUrltiApiIntegration,
  removeSweatAlert,
} from "../../../actions/button";
import { getbillingOrg } from "../../../actions/org";
import { getlogform } from "../../../actions/logs";
import { getOrgList } from "../../../actions/org";
import { env as environment } from "../../../actions/environment";
import AddUserModal from "./AddUserModal.js";
import AddButton from "./AddButton.js";
import ModeTonggle from "../sidebar/ModeTonggle.js";
import SweetAlert from "react-bootstrap-sweetalert";
// import { useIdleTimer } from "react-idle-timer";
import { parseDomain, ParseResultType } from "parse-domain";
import ReactJson from "react-json-view";
/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from "../../../assets/images/logo_dirosvg.png";
//import bell from "../../../assets/images/bell-icon.png"
import ls from "localstorage-slim";

ls.config.encrypt = true;
const Header = ({
  viewdoc_msg,
  getlogform,
  removeSweatAlert,
  roles,
  logTab,
  isAuthenticated,
  getrequested,
  changeUrltiApiIntegration,
  getReviewPending,
  getApproved,
  getRejected,
  getcallbacklogs,
  getpdftojson,
  searchTable,
  getbillingOrg,
  getOrgList,
  headerSearchInput,
  auth: { loading, user, login_user },
  header: {
    subheading,
    search,
    adduser,
    addbutton,
    buttonSettingbut,
    reqdocbut,
    button_id,
  },
  errors: { errorMsg, error_modal },
  table: {
    limit,
    limitapproves,
    limitrejets,
    limitrequest,
    pdfjson,
    pdf_loading,
  },
  logout,
  url,
}) => {
  const [active, setActive] = useState(false);
  const [pdftojsonmodal, setPdftojsonModal] = useState(false);
  const [orgModal, setOrgModal] = useState(false);
  const [jsonData, setjsonData] = useState();
  const [linearProgress, setLinearProgress] = useState(false);
  const [activeTab, setActiveTab] = useState("2");
  const [searchItem, setSearchItem] = useState(null);
  const [searchModal, setSearchModal] = useState(false);
  const [copiedpdf, setCopypdfModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [orgList, setOrgList] = useState();
  const [orgId, setOrgId] = useState();
  const [superAdminId, setSuperAdminId] = useState();
  const [formData, setFormData] = useState({
    enteredString: "",
  });
  const [enteredOrg, setEnteredOrg] = useState("");
  const settings = useSelector((state) => state.settings);
  const authState = useSelector((state)=> state.auth)
  const location = useLocation();

  let pdfjsondata = {};
  pdfjsondata.message = pdfjson.message;
  pdfjsondata.data = pdfjson.data;
  pdfjsondata.status = pdfjson.status;
  pdfjsondata.statusText = pdfjson.statusText;
  useEffect(() => {
    console.log(location.pathname, "Location changed");
    setFormData({ enteredString: "" });
  }, [location.pathname]);

  useEffect(() => {
    // setOrgId(ls.get("orgid"));
    // setSuperAdminId(ls.get("orgid"));

    async function setOrg() {
      axios.defaults.headers.common["Authorization"] = ls.get("token");
      const orgL = await getOrgList();
      setOrgList(orgL?.data);
      console.log("orgL", orgL);
    }
    console.log("SUP", ls.get("orgid"));
    setOrg();
    // //---- Takes to login page on back button click if already logged out  ---
    // window.addEventListener("popstate", (event) => {
    // 	if (ls.get("isLoggedOut") === "true") logout();
    // });
    // //---- Show logged out popup on refresh button click ----
    // if (ls.get("isLoggedOut") === "true") {
    // 	setLoggedOut(true);
    // 	sendLogs("Auto-logout popup", "setLoggedout set to true in localstorage", "actions/auth.js");
    // }

    // const timer = setInterval(() => {
    // 	ls.set("logoutTimer", getRemainingTime());
    // 	// ----- logout after 12 hrs (43200000)-----
    // 	if (ls.get("logoutTimer") === 0 || new Date() - new Date(ls.get("loggedInAt")) >= 43200000) {
    // 		ls.set("isLoggedOut", "true");
    // 		logout();
    // 	}
    // }, 1000);
    // return () => {
    // 	clearInterval(timer);
    // };
  }, []);

  useEffect(() => {
    console.log("ORGID:UE", orgId);
    // orgId ? ls.set("orgid", orgId) : "";

    if (orgId) {
      ls.set("orgid", orgId);
    }
    // apiref();
    getbillingOrg();

    // if (orgId) location.reload();
  }, [getbillingOrg, orgId]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
    // location.reload();
  }

  //--- Auto Logout when the user is idle ---
  //--- Timer starts when this component loads after login ---

  // const timeout = 10 * 3600000; // 10 hours
  //--- react-idle-time setup ---\\
  // const { reset, getRemainingTime } = useIdleTimer({
  // 	timeout,
  // });

  const onApiIntegration = () => {
    changeUrltiApiIntegration(button_id);
    // console.log("hii 1");
    //     // const history = useHistory();
    //     let navigate = useNavigate();
    //     navigate('/client/developers/api-refrence', { replace: true });
    //    // return <Redirect to='/client/developers/api-refrence'/>;
  };

  const { enteredString } = formData;
  const searchModalToggle = () => {
    setSearchModal(!searchModal);
  };

  const onChange = (e) => {

    console.log("onchange entered string",e)
  
    setFormData({ ...formData, enteredString: e });
    e === "" ? setActive(false) : setActive(true);

   headerSearchInput(e)

    
  };

  // const resetSuperadmin = async () => {
  // 	console.log("supeId", superAdminId);
  // 	// setOrgId(superAdminId);
  // 	setTimeout(() => {
  // 		if (subheading === "New Request") {
  // 			console.log("innnn" + limitrequest / 20);

  // 			getrequested(limitrequest / 20);
  // 		} else if (subheading === "Review pending") {
  // 			console.log("in subheading" + limit);
  // 			getReviewPending(limit);
  // 		} else if (subheading === "Approved") {
  // 			console.log("in approve" + limitapproves);
  // 			getApproved(limitapproves);
  // 		} else if (subheading === "Rejected") {
  // 			getRejected(limitrejets);
  // 		}
  // 	}, 1000);
  // 	setOrgModal(false);
  // 	// ls.remove("orgid");
  // 	// ls.set("orgid", "O.IN-U8Y3QD");

  // 	// ls.set("orgid", `${superAdminId}`);
  // 	// location.reload();
  // };

  const onSubmit = async (e) => {
    // const valid_index= ['bank','address',]
    let isValidDocumentId 
    e.preventDefault();
    if (active) {
      console.log("ccccccccc", enteredString, active);

      // if (subheading === "Callback logs") {

        // if(cat.includes(enteredString)){
        //   // isValidDocumentId = true;
          searchTable(enteredString.trim(), subheading);
    console.log( enteredString,"searchItem_mmt")


        // }
        // else{
        //   isValidDocumentId = /^[A-Z]{2}-[a-zA-Z0-9]{6}-*/.test(
        //     enteredString
        //   );
        // }

  // if (isValidDocumentId) {
  //         searchTable(enteredString, subheading);
  //       }
  //     } else {
  //       searchTable(enteredString, subheading);
      // }
    } else {
      if (subheading === "New Request") {
        console.log("innnn" + limitrequest / 20);

        getrequested(limitrequest / 20);
      } else if (subheading === "Review pending") {
        console.log("in subheading" + limit);
        getReviewPending(limit);
      } else if (subheading === "Approved") {
        console.log("in approve" + limitapproves);
        getApproved(limitapproves);
      } else if (subheading === "Rejected") {
        getRejected(limitrejets);
      } else if (subheading === "Callback logs") {
        getcallbacklogs();
        
        console.log("callback");
      }
    }
  };
  const getSearchResult = async (e) => {
    setLinearProgress(true);
    // e.preventDefault();
    console.log("getSearchResult", e);
    const searchData = await searchTable(enteredString);
    setSearchItem(searchData?.data?.rows);
    setSearchModal(true);
    setLinearProgress(false);
  };

  const cancelSearch = async () => {
    setActive(false);
    setLinearProgress(false);
  };
  console.log("searchData--", searchItem);

  const onSubmitRefresh = async (e) => {
    e.preventDefault();
    setFormData({ enteredString: "" });

    if (subheading === "New Request") {
      console.log("innnn" + limitrequest / 20);
      getrequested(limitrequest / 20);
    } else if (subheading === "Review pending") {
      console.log("in subheading" + limit);
      getReviewPending(limit);
    } else if (subheading === "Approved") {
      console.log("in approve" + limitapproves);
      getApproved(limitapproves);
    } else if (subheading === "Rejected") {
      getRejected(limitrejets);
    }

    searchTable(enteredString, subheading);
    window.location.reload();
  };

  const subheadingdata = (
    <div
      style={{
        color: "#212529",
        fontWeight: 400,
        letterSpacing: "2px",
        fontSize: 17,
        display: "flex",
        marginTop: "3px",
      }}
      className="font-weight-medium rounded-top py-3 px-4"
    >
      {/* <i className="mdi mdi-equal mr-2"> </i> */}
      {subheading}
    </div>
  );
  const authLinks = (
    <DropdownItem onClick={logout} href="#!">
      <i className="fa fa-power-off mr-1 ml-1" /> Logout
    </DropdownItem>
  );

  const guestLinks = (
    <DropdownItem href="/authentication/login">
      <i className="fa fa-power-off mr-1 ml-1" /> Login
    </DropdownItem>
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };

  const sidebarHandler = () => {
    let element = document.getElementById("main-wrapper");
    switch (settings.activeSidebarType) {
      case "full":
      case "iconbar":
        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute("data-sidebartype", settings.activeSidebarType);
        }
        break;

      case "overlay":
      case "mini-sidebar":
        element.classList.toggle("full");
        if (element.classList.contains("full")) {
          element.setAttribute("data-sidebartype", "full");
        } else {
          element.setAttribute("data-sidebartype", settings.activeSidebarType);
        }
        break;
      default:
    }
  };

  const handleTab1 = () => {
    console.log("Form closed");
    getlogform();
  };

  //const [dangerSweetAlert, setSweetAlert] = (error_modal);

  const handleSweetAlert = () => {
    console.log("Form closed");
    removeSweatAlert();
    //setSweetAlert(false);
    return <Redirect to="/client/validation-buttons" />;
    // setSweetAlert(false);
  };
  const firstLetterCap = (string) => {
    if (string) {
      const str = string;
      const str2 = str.charAt(0).toUpperCase() + str.slice(1);
      // console.log(str2);
      return str2;
    } else {
      return "Other";
    }
  };
  const openPdftojsonModal = (x, data) => {
    console.log(x);
    // setdid(x);
    getpdftojson(x);
    setPdftojsonModal(true);
    setjsonData(data);
  };
  const selectOrgModal = () => {
    setOrgModal(true);
    // console.log(x);
    // setdid(x);
    // getpdftojson(x);
    // setPdftojsonModal(true);
    // setjsonData(data);
  };
  const closeOrgModal = () => {
    setOrgModal(false);
  };
  const closePdftojsonModal = (x) => {
    console.log(x);
    setPdftojsonModal(false);
    setCopypdfModal(false);
  };
  const onDismiss = () => {
    setCopyModal(false);
    setCopypdfModal(false);
  };
  const copyclipboardPdf = () => {
    //console.log("hello");
    setCopypdfModal(true);
  };
  const getHostnameFromRegex = (url) => {
    // run against regex
    const matches = url?.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    // extract hostname (will be null if no match is found)

    // Get domain
    const parseResult = parseDomain(
      // This should be a string with basic latin letters only.
      matches && matches[1]
    );
    console.log("INSIDE domainfilter");
    // Check if the domain is listed in the public suffix list
    // if (parseResult.type === ParseResultType.Listed) {
    const { subDomains, domain, topLevelDomains } = parseResult;
    const ddomain = domain + "." + topLevelDomains;
    if (domain) {
      return ddomain;
    } else return matches && matches[1];
  };
  const getFullUrl = (url) => {
    // run against regex
    const matches = url?.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    return matches && matches[1];
  };
  const truncate = (str) => {
    return str.length > 10 ? str.substring(0, 7) + "..." : str;
  };
  const getUserTime = (eptime) => {
    var dateMDY = "";
    if (eptime === null || eptime === "" || eptime === undefined) {
      return dateMDY;
    }
    var date = new Date();
    var d = date.getTime();
    var diff = d - eptime;
    switch (true) {
      case 0 <= diff && diff < 60000:
        diff = diff / 1000;
        diff = diff.toFixed(0);
        dateMDY = diff + " sec ago";
        break;
      case 60000 <= diff && diff < 60000 * 60:
        var minD = 60000;
        diff = diff / minD;
        diff = diff.toFixed(0);
        dateMDY = diff + " min ago";
        break;
      case 60000 * 60 <= diff && diff < 60000 * 60 * 24:
        var hrd = 60000 * 60;
        diff = diff / hrd;
        diff = diff.toFixed(0);
        dateMDY = diff + " Hours ago";
        break;
      case 60000 * 60 * 24 <= diff && diff < 60000 * 60 * 24 * 7:
        var dayd = 60000 * 60 * 24;
        diff = diff / dayd;
        diff = diff.toFixed(0);
        dateMDY = diff + " Days ago";
        break;
      case 60000 * 60 * 24 * 7 <= diff && diff < 60000 * 60 * 24 * 7 * 4:
        var weekd = 60000 * 60 * 24 * 7;
        diff = diff / weekd;
        diff = diff.toFixed(0);
        dateMDY = diff + " Weeks ago";
        break;
      case 60000 * 60 * 24 * 7 * 4 <= diff &&
        diff < 60000 * 60 * 24 * 7 * 4 * 3:
        var mond = 60000 * 60 * 7 * 4 * 24;
        diff = diff / mond;
        diff = diff.toFixed(0);
        dateMDY = diff + " Months ago";
        break;
      default:
        var fileDate = new Date(eptime);
        var fileD = fileDate.getDate();
        var fileM = fileDate.getMonth();
        var fileY = fileDate.getFullYear();
        dateMDY = fileD + "-" + fileM + "-" + fileY;
    }
    if (dateMDY === "NaN-NaN-NaN") {
      dateMDY = "";
      return dateMDY;
    } else {
      return dateMDY;
    }
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // ls.set("orgid", item.orgid);
    setOrgId(item.orgid);

    setTimeout(() => {
      if (subheading === "New Request") {
        console.log("innnn" + limitrequest / 20);

        getrequested(limitrequest / 20);
      } else if (subheading === "Review pending") {
        console.log("in subheading" + limit);
        getReviewPending(limit);
      } else if (subheading === "Approved") {
        console.log("in approve" + limitapproves);
        getApproved(limitapproves);
      } else if (subheading === "Rejected") {
        getRejected(limitrejets);
      }
    }, 1000);
    setOrgModal(false);
    // location.reload();

    // location.reload();
    // the item selected
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          Name: {item?.name}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Id: {item?.orgid}
        </span>
      </>
    );
  };

  return (
    <header className="topbar navbarbg" data-navbarbg={settings.activeNavbarBg}>
      {error_modal && errorMsg != "Network Error" ? (
        <SweetAlert
          info
          title="Something went wrong! please try after sometime!!"
          onConfirm={handleSweetAlert}
        >
          {errorMsg}
        </SweetAlert>
      ) : (
        ""
      )}
      <Navbar className={"top-navbar navbar-light"} expand="md">
        <div
          className="navbar-header"
          id="logobg"
          data-logobg={settings.activeLogoBg}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="nav-toggler d-block d-md-none"
            onClick={showMobilemenu.bind(null)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                showMobilemenu();
              }
            }}
            tabIndex="0"
            role="button"
          >
            <i className="ti-menu ti-close" />
          </span>
          {/*--------------------------------------------------------------------------------*/}
          {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
          {/*--------------------------------------------------------------------------------*/}
          <NavbarBrand
            style={{
              paddingTop: "40px",
              display: "flex",
              flexDirection: "column",
            }}
            href="/client/validation-buttons"
          >
            {/* <div>
              <img src={logodarkicon} alt="homepage"/>
            </div>
            <div style={{color:"#5dcbdd", fontSize:"18px", marginTop:"-30px"}}>
              Verification Portal
            </div> */}
            {/* <Link to="/client/validation-buttons"> */}
            <b className="logo-icon">
              <img
                with="78"
                height="36"
                src={logodarkicon}
                className="logo-home"
                alt="homepage"
              />
              <span className="beta-login">{environment.beta}</span>
            </b>
            <span className="logo-text">
              <div
                style={{
                  color: "#5dcbdd",
                  fontSize: "18px",
                  marginTop: "-30px",
                }}
              >
                Verification Portal
              </div>
            </span>
            {/* </Link> */}
          </NavbarBrand>
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="topbartoggler d-block d-md-none mob-tonggle"
            onClick={toggle.bind(null)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                toggle();
              }
            }}
            tabIndex="0"
            role="button"
          >
            <i className="ti-more" />
          </span>
        </div>
        <Collapse
          className="navbarbg d-flex flex-row"
          isOpen={isOpen}
          navbar
          data-navbarbg={settings.activeNavbarBg}
        >
          <Nav className="float-left" navbar>
            {/* <NavItem>
               <NavLink
                href="#"
                className="d-none d-md-block"
                onClick={sidebarHandler.bind(null)}
              >
                <i className="icon-arrow-left-circle"></i>
              </NavLink> 
            </NavItem> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notifications Dropdown                                                   */}
            {/*--------------------------------------------------------------------------------*/}
            {subheading ? subheadingdata : ""}
            {/* {buttonSettingbut ? <Fragment><span className="button-id-header">({ button_id}) </span> </Fragment>: ''} */}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Notifications Dropdown                                                     */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Mega Menu Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
          </Nav>

          <Nav
            className="ml-auto fml-auto d-flex justify-content-end flex-row align-items-center"
            navbar
          >
            <div
              style={{
                height: "28px",
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <SearchBar
								value={enteredOrg}
								onChange={(e) => onChangeOrg(e)}
								style={{ height: "28px", width: "80%", position: "relative" }}
								onRequestSearch={selectOrg}
								// onCancelSearch={cancelSearch}
							/> */}
              {/* {user?.email === "dirolabs@gmail.com" ? (
								<Button onClick={selectOrgModal.bind(null)} className="btn-select-org btn-info btn-color-diro copy-button">
									Select Org.
								</Button>
							) : (
								""
							)} */}
            </div>

            {search ? (
              <Fragment>
                {/* <UncontrolledDropdown nav inNavbar>
									<DropdownToggle nav caret onClick={onSubmitRefresh.bind(null)}>
										<i className="mdi mdi-refresh"></i>
									</DropdownToggle>
								</UncontrolledDropdown> */}
                <Button
                  onClick={onSubmitRefresh.bind(null)}
                  color="warning"
                  className="search-button search-warning reload-btnn"
                >
                  <i className="ti-reload"></i>
                </Button>
              </Fragment>
            ) : (
              ""
            )}
            {search ? (
              <li
                className="nav-item search-box d-none d-md-block d-flex justify-content-center bg-transparent ml-1 mr-3"
                style={{ width: "20vw" }}
              >
                <Form
                  onSubmit={(e) => onSubmit(e)}
                  className="app-search w-100  d-flex justify-content-center"
                >
                  <InputGroup className="search-form d-flex justify-content-end">
                    {/* <Input
												type="text"
												// className="form-control"
												placeholder="Search for..."
												name="enteredString"
												value={enteredString}
												className="search-input"
												onChange={(e) => onChange(e)}
											/> */}
                    <div
                      style={{
                        height: "28px",
                        width: "100%",
                        position: "relative",
                      }}
                      className="raised-search-bar"
                    >

                      <SearchBar
                        value={enteredString}
                        onChange={(e) => onChange(e)}
                        style={{
                          height: "28px",
                          width: "100%",
                          position: "relative",
                        }}
                        onCancelSearch={() => {
                          setFormData({ enteredString: "" });
                          window.location.reload();
                        }}
                      />
                      {linearProgress ? <LinearProgress /> : null}
                    </div>
                  </InputGroup>
                </Form>
              </li>
            ) : (
              ""
            )}

            {subheading === "Callback logs" ? (
              logTab ? (
                <UncontrolledDropdown
                  nav
                  inNavbar
                  className="d-flex align-items-center ml-5"
                >
                  <div
                    className="settingIcon-header"
                    onClick={handleTab1}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        handleTab1();
                      }
                    }}
                    tabIndex="0"
                    role="button"
                  >
                    <i className="ti-settings" />
                  </div>
                </UncontrolledDropdown>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {/* {adduser ? (
							<li className="nav-item search-box d-none d-md-block bg-transparent">
								{" "}
								<AddUserModal />{" "}
							</li>
						) : (
							""
						)} */}

            {authState.authMode !== 1 && (
              (addbutton && roles !== "User") ||
              (addbutton && roles !== "Account") ? (
                <li className="nav-item search-box d-flex align-items-center bg-transparent">
                  <AddButton />{" "}
                </li>
              ) : (
                ""
              )
            )}

           { subheading === "Request document" ? (
            authState.authMode === 1 ? (
                <div data-tip="To add new button, please turn on test mode">
                  <button
                    className="btn btn-secondary float-right icon-hand"
                    style={{
                      marginRight: "10px",
                      padding: "1px 5px",
                      minWidth: "7rem", 
                      opacity: 0.65,
                      cursor: "not-allowed",
                      backgroundColor: "#808080",
                      color: "#fff",
                      borderColor: "#6c757d",
                      boxShadow: "none"
                    }}
                    disabled
                  >
                    + Add button
                  </button>
                  <ReactTooltip place="left" effect="solid" />
                </div>
            ) : null
          ) : null
            }
       
            {buttonSettingbut ? (
              <li className="nav-item search-box d-none d-md-block bg-transparent mr-2">
                <Link
                  to={"/client/developers/api-reference/" + button_id}
                  className="btn btn-outline-primary btn-color-diro-outline float-right mr-2 raised-btn-success"
                  style={{ width: "140px" }}
                >
                  View integration
                </Link>
              </li>
            ) : (
              ""
            )}
            {/* {buttonSettingbut ? (
              <li className="nav-item search-box d-none d-md-block bg-transparent mr-2">
                {" "}
                <Link
                  to={"/client/validation-buttons/req-document/" + button_id}
                  className="btn btn-outline-warning float-right raised-btn-info"
                  style={{ margin: 0, width: "140px" }}
                >
                  Request doc
                </Link>{" "}
              </li>
            ) : (
              ""
            )} */}
            {(reqdocbut && roles !== "User") ||
            (reqdocbut && roles !== "Account") ? (
              <li className="nav-item search-box d-none d-md-block bg-transparent">
                {" "}
                <Link
                  to={"/client/validation-buttons/button-setting/" + button_id}
                  className=" float-right  "
                  style={{ marginTop: "5px" }}
                >
                  {" "}
                  <i className="fas fa-cog fa-2x cog-header"></i>
                </Link>{" "}
              </li>
            ) : (
              ""
            )}
        

            {/*--------------------------------------------------------------------------------*/}
            {/* Start Profile Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar className="bg-transparent">
              <DropdownToggle nav caret className="pro-pic">
                <img
                  src={
                    user?.profileimage
                      ? user.profileimage
                      : "https://stage.dirolabs.com/client/images/user.png"
                  }
                  alt="user"
                  className="rounded sidebar "
                  width="52"
                  height="52"
                  style={{
                    width: "auto",
                    height: "100%",
                    maxHeight: "32px",
                    maxWidth: "125px",
                  }}
                />
              </DropdownToggle>
              <DropdownMenu right className="user-dd animated">
                <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                  {/* <div className="">
                    <img
                      src={user.profileimage ? user.profileimage :("https://stage.dirolabs.com/client/images/user.png")}
                      alt="user"
                      className="rounded"
                      width="80"
                    />
                  </div> */}
                  <div className="ml-2">
                    <h4 className="mb-0">{login_user.name}</h4>
                    <p className=" mb-0">{login_user.email}</p>
                  </div>
                </div>

                <Link
                  to="/client/manage-account/organizations-details"
                  type="button"
                  tabIndex="0"
                  role="menuitem"
                  className="dropdown-item"
                >
                  <i className="ti-user mr-1 ml-1" />
                  Organization
                </Link>

                <Link
                  to="/client/manage-account/billing"
                  type="button"
                  tabIndex="0"
                  role="menuitem"
                  className="dropdown-item"
                >
                  <i className="ti-wallet mr-1 ml-1" />
                  Billing
                </Link>

                <DropdownItem divider />
                {/* <DropdownItem>
                  <Link to="/manage-account/users"><i className="ti-settings mr-1 ml-1" />My account</Link>
                </DropdownItem> */}
                {/* <DropdownItem divider /> */}
                {/* <DropdownItem onClick={logout} href="#!">
                  <i className="fa fa-power-off mr-1 ml-1" /> Logout
                </DropdownItem> */}
                <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>

                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Profile Dropdown                                                           */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Create New Dropdown                                                      */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <i className="flag-icon flag-icon-us"></i>
              </DropdownToggle>
              <DropdownMenu right className="animated bounceInDown">
                <DropdownItem>
                  <i className="flag-icon flag-icon-us"></i> English
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-fr"></i> French
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-es"></i> Spanish
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-de"></i> German
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Create New Dropdown                                                        */}
            {/*--------------------------------------------------------------------------------*/}
          </Nav>
        </Collapse>
      </Navbar>

      <Modal
        isOpen={searchModal}
        toggle={searchModalToggle}
        backdrop={true}
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader toggle={searchModalToggle} className="modal-header-search">
          <div style={{ width: "50%", position: "relative" }}>
            <SearchBar
              value={enteredString}
              onChange={(e) => onChange(e)}
              style={{ height: "35px", width: "100%", position: "relative" }}
              onRequestSearch={getSearchResult}
              onCancelSearch={cancelSearch}
            />
            {linearProgress ? <LinearProgress /> : null}
          </div>
        </ModalHeader>

        <ModalBody
          style={{ height: "80vh", padding: "25px", overflow: "scroll" }}
        >
          <Row>
            <Col md="12">
              <Card
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  marginBottom: "0px",
                }}
              >
                <Table hover striped responsive>
                  {searchItem?.length > 0 ? (
                    <thead
                      style={{
                        backgroundColor: "rgb(0, 188, 212)",
                        color: "white",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      <tr className="table-tr">
                        <th
                          style={{
                            textAlign: "left",
                            width: "15%",
                            paddingLeft: "30px",
                          }}
                          scope="col"
                        >
                          Status
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            width: "15%",
                            paddingLeft: "30px",
                          }}
                          scope="col"
                        >
                          From
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            width: "10%",
                            paddingLeft: "15px",
                          }}
                          scope="col"
                        >
                          Type
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            width: "14%",
                            lineHeight: "1",
                          }}
                          scope="col"
                        >
                          Verification source
                        </th>
                        {/* <th style={{textAlign: "left", width: "11.666"}} scope="col">Document</th> */}
                        <th
                          style={{
                            textAlign: "left",
                            width: "16%",
                            lineHeight: "1",
                          }}
                          scope="col"
                        >
                          Session Id
                        </th>
                        {/* <th style={{textAlign: "left", width: "11.666"}} scope="col">Reason</th> */}
                        <th
                          style={{ textAlign: "left", width: "15%" }}
                          scope="col"
                        >
                          Date
                        </th>
                        {/* <th style={{ textAlign: "left", width: "10%", lineHeight: "1" }} scope="col">
												Track id
											</th> */}
                        <th
                          style={{ textAlign: "left", width: "15%" }}
                          scope="col"
                        >
                          Actions
                        </th>
                        {/* <th style={{ textAlign: "left", width: "10%" }} scope="col"></th> */}
                      </tr>
                    </thead>
                  ) : (
                    ""
                  )}
                  <tbody>
                    {searchItem?.length > 0 ? (
                      searchItem.map((x, index) => (
                        <tr className="table-tr" key={index}>
                          <td
                            style={{
                              verticalAlign: "left",
                              padding: "0px 0 0 14px",
                            }}
                          >
                            {/* <Link to={"/pdf/" + makefileurl(x.phonebooks_shadow.sessionid)} target="blank"> */}
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {x.phonebooks_shadow.file.status === "pending" ? (
                                <>
                                  <i
                                    className="fa fa-circle mr-1 text-warning"
                                    style={{
                                      verticalAlign: "middle",
                                      marginRight: "-4px",
                                    }}
                                    data-tip
                                    data-for="pending"
                                  />
                                  <ReactTooltip
                                    id="pending"
                                    place="top"
                                    effect="solid"
                                  >
                                    Pending
                                  </ReactTooltip>
                                </>
                              ) : x.phonebooks_shadow.file.status ===
                                "verified" ? (
                                <>
                                  <i
                                    className="fa fa-circle mr-1 text-success"
                                    style={{
                                      verticalAlign: "middle",
                                      marginRight: "-4px",
                                    }}
                                    data-tip
                                    data-for="approved"
                                  />
                                  <ReactTooltip
                                    id="approved"
                                    place="top"
                                    effect="solid"
                                  >
                                    Approved
                                  </ReactTooltip>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="fa fa-circle mr-1 text-danger"
                                    style={{
                                      verticalAlign: "middle",
                                      marginRight: "-4px",
                                    }}
                                    data-tip
                                    data-for="rejected"
                                  />
                                  <ReactTooltip
                                    id="rejected"
                                    place="top"
                                    effect="solid"
                                  >
                                    Rejected
                                  </ReactTooltip>
                                </>
                              )}
                              {firstLetterCap(x.phonebooks_shadow.file.status)}
                              <br />
                            </span>
                            {/* </Link> */}
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              padding: "0 0 0 15px",
                              paddingBlock: "11px",
                              width: "20%",
                              lineHeight: "1",
                            }}
                          >
                            {/* <Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank"> */}
                            <span
                              style={{
                                verticalAlign: "middle",
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              <span>
                                <span
                                  className="alignmentapproved"
                                  style={{
                                    textAlign: "left",
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "black",
                                  }}
                                >
                                  {firstLetterCap(
                                    x.phonebooks_shadow.data.user_info.firstname
                                  )}
                                  <br />
                                </span>
                                {/* <span  style={{ textAlign: "left", display: "flex", justifyContent: "left", fontSize: "11px" }}>{x.mxdoc.mobile}</span> */}
                              </span>
                            </span>
                            {/* </Link> */}
                          </td>

                          <td
                            style={{
                              verticalAlign: "left",
                              padding: "0px 0 0 14px",
                            }}
                          >
                            {/* <Link to={"/pdf/" + makefileurl(x.phonebooks_shadow.sessionid)} target="blank"> */}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {firstLetterCap(
                                x.phonebooks_shadow.file.category
                              )}
                              <br />
                            </span>
                            {/* </Link> */}
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              padding: "0px 0 0 14px",
                              lineHeight: "1",
                            }}
                          >
                            {/* <Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank"> */}
                            <span
                              style={{
                                verticalAlign: "middle",
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              {x.phonebooks_shadow.file.urlStatus ? (
                                <React.Fragment>
                                  <span>
                                    <i
                                      className={
                                        x.phonebooks_shadow.file.urlStatus
                                          .urlMatch &&
                                        x.phonebooks_shadow.file.urlStatus
                                          .sslVerified
                                          ? "fa fa-check-circle mr-1 text-success"
                                          : !x.phonebooks_shadow.file.urlStatus
                                              .sslVerified
                                          ? "fa fa-close mr-1 text-danger"
                                          : "fa fa-question mr-1 text-warning"
                                      }
                                      style={{
                                        verticalAlign: "middle",
                                        marginRight: "0px",
                                      }}
                                      data-tip
                                      data-for={
                                        x.phonebooks_shadow.file.urlStatus
                                          .urlMatch &&
                                        x.phonebooks_shadow.file.urlStatus
                                          .sslVerified
                                          ? "success"
                                          : !x.phonebooks_shadow.file.urlStatus
                                              .sslVerified
                                          ? "danger"
                                          : "warning"
                                      }
                                    />
                                  </span>
                                  {x.phonebooks_shadow.file.urlStatus
                                    .urlMatch &&
                                  x.phonebooks_shadow.file.urlStatus
                                    .sslVerified ? (
                                    <ReactTooltip
                                      id="success"
                                      place="top"
                                      effect="solid"
                                    >
                                      Source verified
                                    </ReactTooltip>
                                  ) : !x.phonebooks_shadow.file.urlStatus
                                      .sslVerified ? (
                                    <ReactTooltip
                                      id="danger"
                                      place="top"
                                      effect="solid"
                                    >
                                      Source not verified
                                    </ReactTooltip>
                                  ) : (
                                    <ReactTooltip
                                      place="top"
                                      effect="solid"
                                      id="warning"
                                    >
                                      Verify again
                                    </ReactTooltip>
                                  )}
                                </React.Fragment>
                              ) : (
                                ""
                              )}

                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "black",
                                }}
                                data-tip
                                data-for={x.phonebooks_shadow.file.url}
                              >
                                {getHostnameFromRegex(
                                  x.phonebooks_shadow.file.url
                                )}
                              </span>
                              <ReactTooltip
                                id={x.phonebooks_shadow.file.url}
                                place="top"
                                effect="solid"
                              >
                                {getFullUrl(x.phonebooks_shadow.file.url)}
                              </ReactTooltip>
                            </span>
                            {/* </Link> */}
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              padding: "14px 10px",
                              lineHeight: "1",
                            }}
                          >
                            {/* <Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank"> */}
                            <span
                              style={{
                                textAlign: "left",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {truncate(x.phonebooks_shadow.file.docid)}
                            </span>
                            {/* </Link> */}
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                              padding: "14px 0",
                              lineHeight: "1",
                            }}
                          >
                            {/* <Link to={"/pdf/" + makefileurl(x.phonebooks_shadow.sessionid)} target="blank" data-tip data-for={x.sessionid}> */}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                paddingLeft: "10px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {getUserTime(x.phonebooks_shadow.file.eptime)}
                            </span>
                            {/* </Link> */}
                            <ReactTooltip
                              id={x.phonebooks_shadow.sessionid}
                              place="top"
                              effect="solid"
                            >
                              {`${
                                new Date(
                                  x.phonebooks_shadow.file.eptime * 1
                                ).toDateString() +
                                ", " +
                                new Date(
                                  x.phonebooks_shadow.file.eptime * 1
                                ).toLocaleTimeString()
                              }`}
                            </ReactTooltip>
                          </td>
                          {/* <td style={{ verticalAlign: "middle", padding: "0 0 0 14px", lineHeight: "1" }}>
				<span style={{ justifyContent: "left", fontWeight: "400", fontSize: "12px", color: "black" }}>
					<React.Fragment>
						{x.warncase
							? x.warncase.map((wcase, index1) => (
									<Fragment key={index1}>
										<span>
											{wcase.type === "trackid" ? (
												<React.Fragment>
													<span onClick={tracktoggle.bind(null, x.warncase)} className="trackid-space">
														{firstLetterCap(wcase.message) + ":" + truncate(wcase.keyword)}{" "}
													</span>
													<br />
												</React.Fragment>
											) : (
												""
											)}
										</span>
									</Fragment>
							  ))
							: ""}
					</React.Fragment>
				</span>
			</td> */}
                          <td style={{ alignItems: "center" }}>
                            <div className="d-flex">
                              <Button
                                type="submit"
                                onClick={openPdftojsonModal.bind(
                                  null,
                                  x.phonebooks_shadow.sessionid,
                                  x
                                )}
                                className="btnjson btn-info btn-color-diro copy-button"
                              >
                                JSON
                              </Button>
                              {/* <UncontrolledDropdown>
						<DropdownToggle tag="span" onClick={togglebtn.bind(null, x.sessionid)} data-toggle="dropdown" aria-expanded={isOpen1}>
							<i className="fas fa-ellipsis-v cursor-pointer"></i>
						</DropdownToggle>
						<DropdownMenu className="data-table-dropdown">
							<Link to={"/pdf/" + makefileurl(x.sessionid)} type="button" tabIndex="0" role="menuitem" className="dropdown-item" target="_blank">
								{" "}
								View doc
							</Link>
							<DropdownItem onClick={sessiontoggle.bind(x.sessionid, index, x.sessionid, "sessiontoggle")}> Session details</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={openReportModal.bind(null, x.sessionid, "openReportModal")}> Report issue </DropdownItem>
							<DropdownItem onClick={deleteToggle.bind(null, x.sessionid)}> Delete session</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No data found...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* Pdf to json modal start here */}
      <Modal
        style={{ maxWidth: "90%" }}
        isOpen={pdftojsonmodal}
        toggle={closePdftojsonModal}
      >
        <ModalHeader toggle={closePdftojsonModal}>
          Json viewer
          <span className="right-json-button">
            <CopyToClipboard
              text={
                jsonData?.file?.pdfdata_v3
                  ? JSON.stringify(jsonData.file.pdfdata_v3)
                  : JSON.stringify(pdfjsondata)
              }
              onCopy={copyclipboardPdf.bind()}
            >
              <span className="cursor-pointer">
                <i className="fa fa-copy"></i> Copy
              </span>
            </CopyToClipboard>
          </span>
        </ModalHeader>
        <ModalBody
          className="p-0"
          style={{ height: "80vh", overflow: "scroll" }}
        >
          <Nav tabs className="mb-1">
            <NavItem>
              <NavLink
                className={`${activeTab === "1" ? "active" : ""}`}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                <div className="cursor-pointer">Pdf data</div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === "1" ? "active" : ""}`}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                <div className="cursor-pointer">Raw</div>
              </NavLink>
            </NavItem>
          </Nav>

          <Alert color="info" isOpen={copiedpdf} toggle={onDismiss.bind(null)}>
            Copied!
          </Alert>
          {pdf_loading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            // <ReactJson src={pdfjson} style={{ overflow:"auto"}}/>
            // <pre style={{ marginBottom: "0", height: "75vh" }}>{JSON.stringify(pdfjsondata, null, 2)} </pre>
            <TabContent activeTab={activeTab} className="p-3 pl-5 pr-5">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    {jsonData?.file?.pdfdata_v3 ? (
                      <Table responsive>
                        <thead
                          style={{
                            backgroundColor: "rgb(0, 188, 212)",
                            color: "white",
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          {/* <tr>
														<th>Field</th>
														<th>Value</th>
													</tr> */}
                        </thead>
                        <tbody>
                          {/* {jsonData?.file?.pdfdata_v3[0]?.values[0]?.date ? (
														<tr>
															<td>Date</td>
															<td>{jsonData?.file?.pdfdata_v3[0]?.values[0]?.date}</td>
														</tr>
													) : null} */}
                          {jsonData?.file?.pdfdata_v3.map((item, index) =>
                            item.values.map((x, i) => (
                              <tr>
                                {/* <td>{item.type}</td> */}
                                <td>
                                  {Object.keys(x)[0] === "requested"
                                    ? Object.keys(x)[1]
                                    : Object.keys(x)[0]}
                                  :{" "}
                                  {Object.values(x)[0] === "" ||
                                  Object.values(x)[0] === null
                                    ? Object.values(x)[1]
                                    : Object.values(x)[0]}
                                </td>
                                {/* <td>{item.values.map((x, i) => Object.values(x))}</td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    ) : pdfjsondata?.data?.message !== "Success!" ? (
                      <h4 className="p-4">
                        Switch to Raw tab to view the data
                      </h4>
                    ) : (
                      <h4 className="p-4">
                        Processing result, please wait it may take upto 24 hrs.
                      </h4>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <ReactJson
                    src={
                      jsonData?.file?.pdfdata_v3
                        ? jsonData?.file?.pdfdata_v3
                        : pdfjsondata?.data
                    }
                    collapsed={5}
                    iconStyle={"circle"}
                    displayDataTypes={false}
                    collapseStringsAfterLength={30}
                  />
                </Row>
              </TabPane>
            </TabContent>
          )}
        </ModalBody>
      </Modal>

      <Modal
        style={{ maxWidth: "50%" }}
        isOpen={orgModal}
        toggle={selectOrgModal}
      >
        <ModalHeader toggle={closeOrgModal}>Select Organization</ModalHeader>
        <ModalBody>
          <div
            style={{
              width: "100%",
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "400",
              fontSize: "12px",
              // padding: "20px",
              marginBottom: "0px",
            }}
          >
            {/* <Row md="12"> */}
            <Col md="10">
              {/* <Input
								type="text"
								// className="form-control"
								placeholder="Enter org email"
								name="orgemail"
								value={enteredOrg}
								// className="search-input"
								onChange={(e) => onChangeOrg(e)}
							/> */}
              <div>
                <ReactSearchAutocomplete
                  placeholder="Search org name or id"
                  items={orgList}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  fuseOptions={{
                    shouldSort: true,
                    threshold: 0.3,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: ["name", "orgid"],
                  }}
                  formatResult={formatResult}
                />
              </div>
            </Col>
            {/* <Col md="2">
							<Button onClick={selectOrg} className="btn-info btn-color-diro" style={{ width: "75px" }}>
								Ok
							</Button>
						</Col> */}
            {/* <Col md="2">
							<Button onClick={resetSuperadmin} className="btn-warning " style={{ width: "75px", borderRadius: "25px" }}>
								Reset
							</Button>
						</Col> */}
            {/* </Row> */}
          </div>
        </ModalBody>
      </Modal>
    </header>
  );
};

Header.propTypes = {
  //apiref:  PropTypes.func.isRequired,
  getbillingOrg: PropTypes.func.isRequired,
  getOrgList: PropTypes.func.isRequired,
  changeUrltiApiIntegration: PropTypes.func.isRequired,
  searchTable: PropTypes.func.isRequired,
  getrequested: PropTypes.func.isRequired,
  getReviewPending: PropTypes.func.isRequired,
  getApproved: PropTypes.func.isRequired,
  headerSearchInput:PropTypes.func.isRequired,
  getRejected: PropTypes.func.isRequired,
  getcallbacklogs: PropTypes.func.isRequired,
  getpdftojson: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.func,
  login_user: PropTypes.object,
  auth: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  getlogform: PropTypes.func.isRequired,
  logTab: PropTypes.bool.isRequired,
  roles: PropTypes.string.isRequired,
  error_modal: PropTypes.bool,
  errorMsg: PropTypes.object,
  removeSweatAlert: PropTypes.func.isRequired,
  viewdoc_msg: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  header: state.header,
  isAuthenticated: state.auth.isAuthenticated,
  logform: state.logform,
  roles: state.auth.roles,
  logTab: state.logform.logTab,
  errors: state.errors,
  error_modal: state.errors.error_modal,
  viewdoc: state.viewdoc,
  viewdoc_msg: state.viewdoc.viewdoc_msg,
  table: state.table,
});

export default connect(mapStateToProps, {
  removeSweatAlert,
  getlogform,
  logout,
  searchTable,
  getbillingOrg,
  getOrgList,
  getrequested,
  getReviewPending,
  getApproved,
  getRejected,
  getcallbacklogs,
  getpdftojson,
  headerSearchInput,
  changeUrltiApiIntegration,
})(Header);
