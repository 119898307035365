import { useEffect, useRef, useCallback } from 'react';
import ls from 'localstorage-slim';
import { logout } from '../actions/auth';

const useIdleTimer = ({ dispatch, isAuthenticated }) => {
  const timeoutId = useRef(null);
  const isLoggingOut = useRef(false);
  
  const SESSION_TIMEOUT = 1200000; // 20 minutes in milliseconds

  const handleLogout = useCallback(() => {
    // Prevent multiple logout attempts
    if (isLoggingOut.current) return;
    isLoggingOut.current = true;
    
    console.log('Session timeout - logging out');
    
    // First set the logout flag
    ls.set('isLoggedOut', 'true');
    
    try {
      // Call the logout action
      if (dispatch) {
        dispatch(logout());
      }
      
      // Force redirect after a short delay
      setTimeout(() => {
        window.location.replace('/login');
      }, 500);
    } catch (error) {
      console.error('Error during logout:', error);
      // Fallback redirect
      window.location.replace('/login');
    }
  }, [dispatch]);

  useEffect(() => {
    // Only setup timer if authenticated
    if (!isAuthenticated) return;
    
    const resetTimer = () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      
      timeoutId.current = setTimeout(() => {
        handleLogout();
      }, SESSION_TIMEOUT);
    };

    // Events to watch for activity
    const events = [
      'mousemove',
      'keydown',
      'click',
      'scroll',
      'touchstart'
    ];

    // Setup event listeners
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    // Start initial timer
    resetTimer();

    // Cleanup
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [isAuthenticated, handleLogout]);
};

export default useIdleTimer; 