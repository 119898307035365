import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from "reactstrap";
import DOMPurify from 'dompurify';
//import Spinner from '../view/spinner/Spinner';
import { addUser, getUsers, removeAlert } from "../../../actions/users";
import ls from "localstorage-slim";

ls.config.encrypt = true;
const AddUserModal = ({
	className,
	addUser,
	roles,
	removeAlert,
	getUsers,
	users: { users, userdelerror, users_error, usererror, loading },
	history,
}) => {
	console.log("roles", roles);
	const [addmodal, setAddModal] = useState(false);

	const [err, seterror] = useState({
		firstName: "",
		emailId: "",
		role: "",
	});
	useEffect(() => {
		// getUsers();
	}, [getUsers]);

	const addToggle = () => {
		// //console.log("hii");
		setAddModal(!addmodal);
		seterror({ firstName: "", emailId: "", role: "" });
	};

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		emailId: "",
		mobileNumber: "",
		role: "",
		apiKey: ls.get("apikey"),
		adminEmail: ls.get("email"),
		country: ls.get("country"),
		// signUp: true,
	});

	//const { firstName, lastName, emailId, mobileNumber, role, apiKey} = formData;

	// const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const sanitizeInput = (input) => {
        return DOMPurify.sanitize(input, {ALLOWED_TAGS: [], ALLOWED_ATTR: []});
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: sanitizeInput(value),
        });
    };

	const onSubmit = async (e) => {
		e.preventDefault();
		// seterror({
		//   firstName: '',
		//   emailId: '',
		//   role: ''
		// })
		if (handleValidation()) {
			//  alert("Form submitted");
			addUser(formData, history, true);
			setAddModal(!addmodal);
			//  setAddModalAlert(true)
			setFormData({
				firstName: "",
				lastName: "",
				emailId: "",
				mobileNumber: "",
				role: "",
				apiKey: ls.get("apikey"),
				adminEmail: ls.get("email"),
				country: ls.get("country"),
				// signUp: true,
			});
		}

		console.log("er" + err.firstName);
	};

	const handleValidation = () => {
		//console.log("handle validation")
		let fields = formData;
		let errors = {};
		let formIsValid = true;

		//first Name
		if (!fields["firstName"]) {
			formIsValid = false;
			errors["firstName"] = "Cannot be empty";
		}

		//Email
		if (!fields["emailId"]) {
			formIsValid = false;
			errors["emailId"] = "Cannot be empty";
			console.log("in1");
		}
		if (!fields["role"] || fields["role"] === "-- Select your role --") {
			formIsValid = false;
			errors["role"] = "Please select role";
		}

		if (typeof errors["emailId"] == "undefined") {
			let lastAtPos = fields["emailId"].lastIndexOf("@");
			let lastDotPos = fields["emailId"].lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					fields["emailId"].indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					fields["emailId"].length - lastDotPos > 2
				)
			) {
				formIsValid = false;
				errors["emailId"] = "Email is not valid";
				console.log("in2");
			}
		}

		//console.log("err " +  errors.firstName);
		seterror({
			firstName: errors.firstName,
			emailId: errors.emailId,
			role: errors.role,
		});
		//console.log(err.errors);
		return formIsValid;
	};

	return (
		<Fragment>
			{roles !== "User" && roles !== "Account" ? (
				<button
					className="btn btn-outline-warning float-right  raised-btn-info"
					style={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px" }}
					onClick={addToggle.bind()}
				>
					+ Add User
				</button>
			) : (
				""
			)}
			{/* <AddUsersModal addmodals={addmodal} /> 
       <EditUsersModal editmodal={editmodal} />  */}
			{/*******************Add Modal Starts Here**********************/}
			<Modal style={{ maxWidth: "600px" }} isOpen={addmodal} toggle={addToggle.bind(null)} className={className}>
				<ModalHeader toggle={addToggle.bind(null)}>Add user</ModalHeader>
				<form id="loginform" onSubmit={(e) => onSubmit(e)}>
					<ModalBody>
						<Row>
							<Col md="12">
								<FormGroup>
									<Label>
										Role<span className="asterisk">*</span>
									</Label>
									<Input
										type="select"
										onChange={(e) => onChange(e)}
										name="role"
										className={"form-control" + (err.role === "Please select role" ? " is-invalid" : "")}
										style={{
											backgroundPosition: "right 0.95rem center, right 1.75rem center"
										}}
									>
										<option>-- Select your role --</option>
										{roles}
										{roles === "Admin" ? <option value="Admin"> Admin (Complete access & manage users)</option> : ""}
										<option value="Developer"> Developer (Access to API key & token)</option>
										<option value="User"> User (Access to only his own documents)</option>
										<option value="Account"> Account (Access to accounts section)</option>
										<option value="Manager"> Manager (Access to documents of all users)</option>
									</Input>
									{err.role === "Please select role" ? 
										<div className="invalid-feedback" style={{display: "block", clear: "both"}}>
											{err.role}
										</div> : ""}
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<Label>
									First name<span className="asterisk">*</span>
								</Label>
								<Input
									type="text"
									onChange={(e) => onChange(e)}
									name="firstName"
									placeholder="Enter first name"
									className={"form-control" + (err.firstName === "Cannot be empty" ? " is-invalid" : "")}
								/>
								{err.firstName === "Cannot be empty" ? <div className="invalid-feedback">{err.firstName}</div> : ""}
							</Col>
							<Col md="6">
								<Label>Last name</Label>
								<Input type="text" placeholder="Enter last name" onChange={(e) => onChange(e)} name="lastName" />
							</Col>
						</Row>
						<Row className="pt-3">
							<Col md="12">
								<Label>
									Email<span className="asterisk">*</span>
								</Label>
								<Input
									type="text"
									placeholder="Enter email"
									onChange={(e) => onChange(e)}
									name="emailId"
									className={"form-control" + (err.emailId === "Cannot be empty" || err.emailId === "Email is not valid" ? " is-invalid" : "")}
								/>
								{err.emailId === "Cannot be empty" || err.emailId === "Email is not valid" ? (
									<div className="invalid-feedback">{err.emailId}</div>
								) : (
									""
								)}
							</Col>
						</Row>
						<Row className="pt-3">
							<Col md="12">
								<Label>Mobile Number</Label>
								<Input type="number" placeholder="Enter mobile number" onChange={(e) => onChange(e)} name="mobileNumber" />
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button className="btn transparent" onClick={addToggle.bind(null)}>
							Cancel
						</Button>
						<button className="btn btn-outline-warning" type="submit">
							Confirm
						</button>
					</ModalFooter>
				</form>
			</Modal>
			{/*******************Add Modal Ends Here**********************/}
		</Fragment>
	);
};

AddUserModal.propTypes = {
	addUser: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired,
	removeAlert: PropTypes.func.isRequired,
	users: PropTypes.object,
	roles: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	users: state.users,
	auth: state.auth,
	roles: state.auth.roles,
});

export default connect(mapStateToProps, { addUser, getUsers, removeAlert })(AddUserModal);
